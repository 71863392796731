body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-login{
  background: url("../public/assets/images/phone-in-hand.jpg");
  background-position:center;
  background-size: contain; /* Cambia 'cover' por 'contain' */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.bg-image{
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
  height: 100%; /* Asegura que ocupe toda la altura disponible */
  display: flex; /* Mantiene la alineación */
  justify-content: center;
  align-items: center;
}
.image-forgot{
  background: url("../public/assets/images/reset-password.jpg");
  background-position:center;
  background-size: contain; /* Cambia 'cover' por 'contain' */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.title-login{
 color: #3a3b45!important;
 margin-bottom: 1.5rem !important;
 font-size: 1.5rem;
 font-weight: 400;
 line-height: 1.2;
}

hr{
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

@keyframes bounce {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
}